import { generateUniqueIdentifier, applyMixins } from 'commons/utils';
import { WbEnvironments, WbSessionActions } from 'enums';
import { InterWbLogger, InterWbResponseHandler } from '.';
export class InterWebviewBridge {
    constructor() {
        this.isAndroid = !!window.Android;
        this.getNativeBridge = () => { var _a; return window.Android || ((_a = window.webkit) === null || _a === void 0 ? void 0 : _a.messageHandlers); };
        /**
         * @summary Registra um evento previamente mapeado na bridge
         */
        this.addWebviewEventListener = (type, _callback) => this.registerListener(type, _callback);
        /**
         * @summary Remove eventos
         * @param _callback - função a ser removida
         */
        this.removeWebviewEventListener = (type, _callback) => window.removeEventListener(type, _callback);
        this.info('Constructor', 'private', `Iniciando classe`);
    }
    /**
     * @summary Retorna uma instância única de InterWebviewBridge
     * @returns - instanceof InterWebviewBridge
     */
    static getInstance() {
        var _a, _b;
        if (!InterWebviewBridge.instance) {
            applyMixins(InterWebviewBridge, [InterWbResponseHandler, InterWbLogger]);
            InterWebviewBridge.instance = new InterWebviewBridge();
            const url = (_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.href) !== null && _b !== void 0 ? _b : '';
            const env = InterWebviewBridge.instance.getEnvironment();
            if (env !== 'BROWSER') {
                InterWebviewBridge.instance.execute({
                    action: WbSessionActions.REQUEST_ANALYTICS,
                    metadata: { name: 'WEBVIEW_URL', params: { mainUrl: url, native: 'false' } },
                });
            }
        }
        return InterWebviewBridge.instance;
    }
    static clearInstance() {
        if (InterWebviewBridge.instance) {
            this.instance = null;
        }
    }
    registerListener(evtId, _callback) {
        return new Promise((resolve, reject) => {
            const listener = (event) => {
                try {
                    const data = this.wbResponseHandler(event);
                    this.info(event.detail.action, evtId, `Recebido: ${JSON.stringify(data)}`);
                    resolve(data);
                }
                catch (error) {
                    this.error(event.detail.action, evtId, String(error));
                    reject(error);
                }
                window.removeEventListener(evtId, listener);
            };
            window.addEventListener(evtId, _callback || listener);
        });
    }
    /**
     * Retorna o ambiente atual
     * @returns - string: 'BROWSER', 'ANDROID' or 'IOS'
     */
    getEnvironment() {
        const isMobile = !!this.getNativeBridge();
        if (!isMobile)
            return WbEnvironments.BROWSER;
        if (this.isAndroid)
            return WbEnvironments.ANDROID;
        return WbEnvironments.IOS;
    }
    /**
     * Faz uma request para a bridge Webview
     * @param request - IWbBaseRequest com o metadata setado
     * @returns - Promise com o IWbBaseResponse.data esperado
     */
    execute(request) {
        if (!this.getNativeBridge())
            return Promise.reject(new Error('Ambiente mobile não detectado!'));
        const eventName = generateUniqueIdentifier();
        const promise = this.registerListener(eventName);
        const body = JSON.stringify(Object.assign(Object.assign({}, request), { evtId: eventName }));
        if (this.isAndroid)
            this.getNativeBridge().execute(body);
        else
            this.getNativeBridge().execute.postMessage(body);
        this.info(request.action, eventName, `Executando ${body}`);
        return promise;
    }
}
