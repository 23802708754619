import { InterWebviewBridge } from 'core/InterWebviewBridge';
import { WbAuthorizationActions } from 'enums/InterWbActions';
const InterWbAuth = () => {
    const iWb = InterWebviewBridge.getInstance();
    return {
        /**
         * Faz uma requisição ao nativo pedindo os dados do iSafe
         * @param category - categoria
         * @param checkType - tipo da transação iSafe
         * @param feature - feature/permissão do iSafe
         * @param value - valor
         * @param is3fa - ativa o fluxo de 3FA do iSafe
         * @param transactionId - Id da transação em caso de retentativa
         * @returns - IWbISafeResponse
         *
         */
        requestISafe(category, checkType, feature, value, is3fa, transactionId) {
            return iWb.execute({
                action: WbAuthorizationActions.REQUEST_ISAFE,
                metadata: {
                    category,
                    checkType,
                    feature,
                    value,
                    is3fa,
                    transactionId,
                },
            });
        },
    };
};
export const interWbAuth = InterWbAuth();
