import React, { memo, useMemo } from 'react'

import Slider, { Settings } from 'react-slick'

import * as S from './styles'

export type CarouselProps = {
  dotsType: 'small' | 'medium' | 'big'
  verticalMode: boolean
  children: React.ReactNode
  className?: string
  containerStyles?: React.CSSProperties
} & Settings

export const Component = ({
  dots = true,
  dotsType = 'small',
  verticalMode = false,
  children,
  className,
  containerStyles,
  ...rest
}: CarouselProps) => {
  const settings = useMemo(
    () => ({
      dots,
      vertical: verticalMode,
      verticalSwiping: verticalMode,
      nextArrow: <S.ArrowRight data-testid="arrow-right" />,
      prevArrow: <S.ArrowLeft data-testid="arrow-left" />,
      ...rest,
    }),
    [dots, rest, verticalMode],
  )

  return (
    <S.Container
      data-testid="carousel"
      className={className}
      hasTypeDots={dotsType}
      style={containerStyles}
    >
      <Slider {...settings}>{children}</Slider>
    </S.Container>
  )
}

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/pages-utils-carousel-since-v2-8-0--default)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/7QVC2u96bJmyW8qQ8mBNb3/DS-%2F-Components-%2F-Global?node-id=8555-3453&t=L8oDOAllJnWoJXdv-0)
 *
 * **Code example**
 * ```tsx
 * import React from 'react'
 *
 * import { Carousel } from '@interco/inter-ui/components/Carousel'
 *
 * export const MyPage = () => (
 *   <Carousel
 *     dots
 *     arrows
 *     infinite={false}
 *     speed={500}
 *     slidesToShow={1}
 *     dotsType="big"
 *     verticalMode={false}
 *   >
 *     <div>
 *       <h3>Conteúdo 1</h3>
 *     </div>
 *
 *     <div>
 *       <h3>Conteúdo 2</h3>
 *     </div>
 *
 *     <div>
 *       <h3>Conteúdo 3</h3>
 *     </div>
 *   </Carousel>
 * )
 * ```
 */
export const Carousel = memo(Component)
